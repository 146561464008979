import * as dompack from "dompack";

import Nubshop from "@mod-nubshop/web/pages/webshop/webshop";
import * as mso from "@mod-mso/webdesigns/mso/mso";

import "@mod-mso_templates/webdesigns/penitus/penitus";

import "./anylabel.scss";

dompack.onDomReady(() => {
  new mso.MSOSite();
  new Nubshop();
});
